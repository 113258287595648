const adminRouter = {
  route: null,
  name: null,
  title: "设置",
  type: "folder",
  icon: "iconfont icon-huiyuanguanli",
  filePath: "view/admin/",
  order: null,
  inNav: true,
  permission: ["超级管理员独有权限"],
  children: [
    {
      title: "资料项管理",
      type: "view",
      name: "setting",
      route: "/setting/setting",
      filePath: "view/setting/setting.vue",
      inNav: true,
      icon: "iconfont icon-zidingyi",
    },
    // {
    //   route: '/admin/group/list',
    //   name: null,
    //   title: '特殊配制',
    //   type: 'tab', // 取 route 为默认加载页
    //   icon: 'iconfont icon-yunyingguanli_fuwufenzuguanli',
    //   filePath: 'view/admin/group',
    //   inNav: true,
    //   children: [
    //     {
    //       route: '/admin/group/list',
    //       type: 'view',
    //       name: 'groupList',
    //       inNav: true,
    //       filePath: 'view/admin/group/group-list.vue',
    //       title: '分组列表',
    //       icon: 'iconfont icon-huiyuanguanli',
    //       permission: ['超级管理员独有权限'],
    //     },
    //     {
    //       route: '/admin/group/add',
    //       type: 'view',
    //       name: 'GroupCreate',
    //       filePath: 'view/admin/group/group-create.vue',
    //       inNav: true,
    //       title: '添加分组',
    //       icon: 'iconfont icon-add',
    //       permission: ['超级管理员独有权限'],
    //     },
    //     {
    //       route: '/admin/group/edit',
    //       type: 'view',
    //       name: 'GroupEdit',
    //       filePath: 'view/admin/group/group-edit.vue',
    //       inNav: false,
    //       title: '修改分组',
    //       icon: 'iconfont icon-add',
    //       permission: ['超级管理员独有权限'],
    //     },
    //   ],
    // },
    // {
    //   route: '/admin/user',
    //   name: null,
    //   title: '权限管理',
    //   type: 'folder', // 取 route 为默认加载页
    //   icon: 'iconfont icon-huiyuanguanli',
    //   filePath: 'view/admin/user/',
    //   inNav: true,
    //   children: [
    //     {
    //       title: '员工管理',
    //       type: 'view',
    //       name: 'employee-list',
    //       route: '/admin/employee/list',
    //       filePath: 'view/admin/employee/list.vue',
    //       inNav: true,
    //       icon: 'iconfont icon-huiyuanguanli',
    //       permission: ['超级管理员独有权限'],
    //     },
    //     {
    //       title: '添加员工',
    //       type: 'view',
    //       name: 'employee-create',
    //       route: '/admin/employee/add',
    //       filePath: 'view/admin/employee/add.vue',
    //       inNav: false,
    //       icon: 'iconfont icon-huiyuanguanli',
    //       permission: ['超级管理员独有权限'],
    //     },
    //   ],
    // },
  ],
};

export default adminRouter;
