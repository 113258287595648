<template>
  <el-config-provider :locale="locale">
    <div id="app">
      <router-view v-if="isRouteActive" />
    </div>
  </el-config-provider>
</template>

<script>
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import { provide, ref, nextTick, onMounted } from "vue";

export default {
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },
  // data() {
  //   return {
  //     locale: zhCn
  //   }
  // },

  setup() {
    onMounted(() => {
      document.getElementById("loader").style.display = "none";
    });
    const isRouteActive = ref(true);
    provide("reload", () => {
      isRouteActive.value = false;
      nextTick(() => {
        isRouteActive.value = true;
      });
    });
    return {
      isRouteActive: isRouteActive,
      locale: zhCn,
    };
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition-delay: 99999s;
    transition: color 99999s ease-out, background-color 99999s ease-out;
  }
}
</style>
