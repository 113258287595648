const generalRouter = {
  route: null,
  name: null,
  title: "概览",
  type: "folder", // 类型: folder, tab, view
  icon: "iconfont icon-jiemianicon-",
  filePath: "view/book/", // 文件路径
  order: 1,
  inNav: true,
  children: [
    {
      title: "铸造",
      // type: 'view',
      type: "view",
      name: "mint-list",
      route: "/mint/list",
      filePath: "view/mint/mint.vue",
      inNav: true,
      icon: "iconfont icon-moshubang",
    },
    // {
    //   route: '/mint/add',
    //   type: 'view',
    //   name: 'mint-create',
    //   inNav: false,
    //   filePath: 'view/mint/add.vue',
    //   title: '添加铸造',
    //   icon: 'iconfont icon-add',
    // },
    // {
    //   route: '/mint/edit/:id',
    //   type: 'view',
    //   name: 'mint-edit',
    //   inNav: false,
    //   filePath: 'view/mint/edit.vue',
    //   title: '编辑铸造',
    //   icon: 'iconfont icon-icon-test',
    // },
    // {
    //   title: '赠送',
    //   type: 'view',
    //   name: 'send-list',
    //   route: '/send/list',
    //   filePath: 'view/send/list.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-fenxiang',
    // },
    // {
    //   title: '空投',
    //   type: 'view',
    //   name: 'gift-list',
    //   route: '/gift/list',
    //   filePath: 'view/gift/list.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-multiple_inputs',
    // },
    // {
    //   route: '/gift/add/:id',
    //   type: 'view',
    //   name: 'gift-create',
    //   inNav: false,
    //   filePath: 'view/gift/add.vue',
    //   title: '添加空投',
    //   icon: 'iconfont icon-add',
    // },
    {
      title: "领取",
      type: "view",
      name: "receive-list",
      route: "/receive/list",
      filePath: "view/receive/list.vue",
      inNav: true,
      icon: "iconfont icon-shoucang",
    },
    // {
    //   title: '兑换',
    //   type: 'view',
    //   name: 'exchange-list',
    //   route: '/exchange/list',
    //   filePath: 'view/exchange/list.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-table1',
    // },
  ],
};

export default generalRouter;
